@import 'overrides/overrides';
@import '../../cremedelamer2_base/scss/cremedelamer2-base-mobile';
@import 'sections/all';
@import 'liveperson';
@import '../../cremedelamer2_base/scss/sections/one_trust_cookie/cookie_settings_ot';
@import 'sections/checkout/checkout-viewcart';

.site-header {
  &__utility-left {
    &.utilitynav-mobile {
      display: inline-block;
      position: absolute;
      right: 50px;
      top: 14px;
      .site-header__utility-item {
        display: none;
        &.lang-country-select {
          display: block;
        }
      }
    }
  }
  &__utility-item-nav {
    margin-left: 10px;
  }
  &__utility-right {
    margin-right: 20px;
    .gnav-util {
      &__icon {
        span {
          position: absolute;
          top: 4px;
          &.cart--bracket {
            display: none;
          }
        }
        &__cart-count {
          width: 23px;
        }
        .icon--bag {
          position: static;
          font-size: 14px;
          padding-left: 0;
          width: 23px;
          height: 23px;
          &::before {
            font-size: 23px;
          }
        }
      }
    }
  }
  &__utility-item {
    .lang--active,
    .spliter {
      font-weight: bold;
    }
  }
}

.sticky__live-chat {
  &__mobile {
    bottom: 30%;
    position: fixed;
    #{$rdirection}: 4px;
    text-align: center;
    &--link {
      background: $color-white;
      border: 1px solid $color-gray;
      border-radius: 50%;
      display: block;
      height: 25px;
      width: 25px;
      img {
        margin-top: 4px;
        vertical-align: top;
      }
    }
  }
}
